.contenedor_footer{
  background: black url("../images/sitio/FOOTER/banner_footer.jpg") no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 30px;
}
/** PRIMER CONTENEDOR **/
.contenedor_contacto{
  align-content: center;
}
.contenedor_contacto_2{
  padding: 10px;
}
.titulo_contacto{
  font-size: 2em;
  color:#ffffff;

}
.logo_canimolt_footer{
  width: 50%;
}
.icono_contacto{
  width: 40px;
  margin: 0px;
}
.text_contacto{
  color:#ffffff;
  font-size: 1em;
  text-align: left;
  margin: 0px;
}
.link_footer{
  color:#ffffff;
  text-decoration: none;
}
.link_footer:hover{
  color:#ffffff;
}
/** AREA SUSCRIBIRSE **/
.contenedor_suscribete{
  border-left: 2px solid #ffffff;
  padding-left:20px;
}
.texto_suscribete{
  color:#ffffff;
  font-size: 1em;
  text-align: center;
}
.input_suscribirse{
  background-color: white !important;
    color: white !important;
    width:100%;
    border: 1px solid white;
}
.contenedor_enviar_suscribirse{
  color:#000000;
  background-color: #fccd35;
  border: 2px solid #fccd35;
}
/** ENVIAR MENSAJE **/
.text_form_contacto{
  color:white;
}
.text_form_contacto_error{
  color:red;
  font-weight: bold;
}
.input_contacto{
  color:white !important;
  background-color: black !important;    
}
.input_contacto{
  color:white !important;
  background-color: black !important;    
}
.enviar_mensaje_contacto{
  color:#000000;
  font-weight: bold;
}
.boton_enviar_mensaje{
  background-color: #fccd35!important;
  border: #fccd35!important;
}
.form_enviar_color{
  background-color: #000000;
  padding: 10px;
}
.footer_derechos_contenedor{
  background-color: #000000;
}
.footer_derechos{
  color:#ffffff;
  font-family: 'MyriadPro Regular';
  font-size: 1em;
  margin: 0px;
}
.logo_derechos{
  width: 50px;
}