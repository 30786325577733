.banner_principal_trigo{
    height: cover;
    background: url('../images/sitio/4_trigo/banner_secciontrigo.jpg');
    background-size: 110% 110%;
    background-position: center center;
    animation: shrink 5s infinite alternate;
    padding: 5%;
  }
  @keyframes shrink {
    0% {
      background-size: 110% 110%;
    }
    100% {
      background-size: 100% 100%;
    }
  }

  .contenedor_icono_principal_trigo{
      text-align: center;
  }

  .img_icono_principal_trigo{
    width: 350px;
    margin-top: 0%;
  }

  .palabras_principal_trigo{
      font-size: 5em;
      color:white;
      font-weight: bold;
  }

  .texto_titulo_trigo{
    font-size: 2em;
      font-weight: bold;
      padding-bottom: 0px;
      margin-bottom: 0px;
      text-align: left;
      color:black;
  }

  .contenedor_espiga_trigo{
    position: relative;
    text-align: right;
  }

  .img_espiga_trigo{
      width:500px;
      animation: espiga_trigo 2s;
      float:right;
      margin-left: 15px;
  }

  .texto_info_trigo{
      font-size: 1.5em;
      margin-top:20px;
      margin-bottom: 20px;
      text-align: justify;
  }

  @keyframes espiga_trigo {
    0% {
      margin-right: -500px;
    }
    100% {
        margin-right: 0px;
    }
  }

@media screen and (max-width:767px){
    .img_espiga_trigo{
        width:200px;
    }
    .img_icono_principal_trigo{
        width: 250px;
        margin-top: 15%;
    }
    .palabras_principal_trigo{
        font-size: 3em;
    }
}

.border-left-amarillo{
    border-left:10px solid #fccd35;
    padding-left:10px;
}

.morfoligia_planta{
    width: 100%;
}

.texto_morfologia_planta{
    font-size: 1.5em;
    text-align: justify;
}

.img_estructura_grano{
    width:100%;
}

.contenedor_bandera{
    position:relative;
    height: 100px;
    margin: 30px 0px;
}

.contenedor_bandera_pais{
    position:absolute;
    z-index:99;
    left:0px;
}


.bandera_pais{
    width:100px;
}

.contenedor_texto_pais{
    color:white;
    background: black;
    padding: 10px 60px;
    font-weight: bold;
    text-align: right;
    font-size: 30px;
    position:absolute;
    left:70px;
    margin-top:20px;
}

.contenedor_tabla_trigo_pais{
    border-bottom: 1px solid lightgray;
}

.texto_nombre_tipos_grano{
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
}

.texto_textos_tipos_grano{
    color: gray;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
}

@media screen and (max-width:575px){

    .contenedor_texto_pais{
        color:white;
        background: black;
        padding: 10px 30px;
        font-weight: bold;
        text-align: right;
        font-size: 20px;
        position:absolute;
        left:70px;
        margin-top:20px;
    }

    .texto_nombre_tipos_grano{
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        font-size: 12px;
    }

    .texto_textos_tipos_grano{
        color: gray;
        text-align: justify;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
    }

}

.img_trigo_mexico{
    float: left;
    width: 400px;
}

@media screen and (max-width:767px){
    .img_trigo_mexico{
        float: left;
        width: 200px;
    }
}