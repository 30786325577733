.contenedor_numeros{
    width: 100%!important;
    position: absolute!important;
    padding-bottom: 7%!important;
}
.contador{
    background-color: #fccd35;
    border: #000000 solid 2px;
    border-radius: 5px;
}
.numero{
    font-size: 35px;
    color: #ffffff;
    font-weight: bold;
}
.separador1{
    font-size: 35px;
    color: #fccd35;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}
.separador{
    font-size: 35px;
    color: #fccd35;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}
@media only screen and (max-width: 768px) {
    .numero{
        font-size: 15px;
    }
    .contenedor_numeros{
        padding-bottom: 0%!important;
    }
    .separador1, .separador{
        font-size: 25px;
        margin-bottom: 13px;
    }
    .contador{
        border: #000000 solid 1px;
    }
    
}