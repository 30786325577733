.banner_seccion_blog{
  height: cover;
  background: url('../images/sitio/publicaciones/banner_cerelaes_trigo.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 2%;
}
@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}
/* VISTA GENEREAL */
.img_articulo_blog{
  width: 100%;
}
.contenedor_blog_web{
  padding: 3% 0%;
}
.titulo_blog_banner{
  color:#fccd35;
  font-size: 3.5em;
  font-family: 'MyriadPro Regular';
  margin: 0px;
  font-weight: bold;
}
.texto_blog_banner{
  font-family: 'MyriadPro Regular';
  color: #ffffff;
  font-size: 2.5em;
  padding: 0% 20%;
}
.nuestro_blog{
  font-family: 'MyriadPro Regular';
  font-size: 2.5em;
  border-bottom: 2px solid #000000;
  font-weight: bold;
  text-align: left;
  margin: 0px;
  padding-top: 2%;
}

.contenedor_blog_movil{
  padding: 3% 0%;
}

/* CARDS */
.titulo_articulo_blog{
  font-family: 'MyriadPro Regular';
  font-size: 1.8em;
  text-align: left;
  margin: 0%;
}
.prev_articulo_blog{
  font-family: 'MyriadPro Regular';
  font-size: 1.5;
  color:#686f7b;
  text-align: justify;
}
.contenedor_boton_blog{
  text-align: -webkit-left;
}
.contenedor_boton_blog a{
  width: fit-content;
}

.btn-blanco-blog {

  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #e74c3c;
  border-radius: 0.0em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  line-height: 1;
  padding: 0.6em 0.2em;
  text-decoration: none;
  text-align: center;
}
.btn-blanco-blog:hover, .btn-blanco-blog:focus {
  color: #fff;
  outline: 0;
}
.boton-blanco-blog {
  border-color: #fccd35;
  color:#000000;
  background-image: -webkit-linear-gradient(90deg, #000000 50%, transparent 50%);
  background-image: linear-gradient(90deg, #000000 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}

.boton-blanco-blog:hover {
  background-position: 0;
}

/* CATEGORIAS */
.contenedor_categorias{
  text-align: left;
}

.titulo_categorias{
  color: #000000;
  font-weight: bold;
  font-size: 1.4em;
}

.opciones_categoria{
  color: #686f7b;
  font-size: 1.2em;
  line-height: 2;
}

.opcion_categoria{
  color: #686f7b;
  border-bottom: 1px solid #686f7b;
}

.opcion_categoria:hover{
  cursor:pointer;
}

.opcion_categoria_activa{
  color: #686f7b;
  font-weight: bold;
  border-bottom: 2px solid #686f7b;
}

