.contenedor_error404{
  background-image: url('../images/sitio/11_ERROR_404/fondo_campotrigo.jpg');
  size: 100% 100%;
  height: cover;
  width: cover;
  padding: 5%;
}
.logo_error{
  width: 100%;
}
.texto_error{
  font-size: 2em;
  color: #ffffff;
}

.btn-negro {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #e74c3c;
  border-radius: 0.1em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  line-height: 1;
  padding: 0.6em 1.6em;
  text-decoration: none;
  text-align: center;
}
.btn-negro:hover, .btn-negro:focus {
  color: #fff;
  outline: 0;
}

.boton-negro {
  border-color: black;
  color:#000000;
  background-image: -webkit-linear-gradient(90deg, #000000 50%, transparent 50%);
  background-image: linear-gradient(90deg, #000000 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}

.boton-negro:hover {
  background-position: 0;
}
.contenedor_boton_error{
  text-align: -webkit-center;
}
.contenedor_boton_error a{
  width: fit-content;
}