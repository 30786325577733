.contenedor_inicio_conocenos{
    background-color:black;
    padding-top:50px;
}

.contenedor_lado_derecho_inicio{
    padding-left: 30px;
    padding-right: 30px;
}

.titulo_inicio_cocnocenos{
    color: white;
    font-size: 4em;
    text-align: left;
    margin: 0px;
}

.texto_inicio_cocnocenos{
    font-size: 14px;
    text-align: justify;
    color:white;
}

.contenedor_flecha_izquierda{
    text-align: center !important;
}

.contenedor_flecha_derecha{
    text-align: center !important;
}

.img_flecha_inicio_conocenos{
    width: 35px;
    cursor:pointer;
}

.palabra_conocecnos_inicio{
    font-size: 25px;
    text-align: center;
    color:white;
    border-bottom: 1px solid white;
}

.contenedor_img_inicio_conocenos{
    text-align: center;
}

.img_inicio_conocenos{
    width: 100%;
}

.imagen_logo_grande{
    width:100%;
}

.titulo_marcas_conocenos{
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 0px;
}

.contenedor_tabla_consejo_conocenos{
    background: url('../images/sitio/2_CONOCENOS/back-consejodirectivo.jpg');
    background-size: 100% 100%;
}

.titulo_consejo_conocenos{
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 0px;
}

.cargo_consejo_conocenos{
    text-align: left;
    font-size: 16px;
}

.empresa_consejo_conocenos{
    text-align: left;
    font-size: 16px;
}

@media screen and (max-width:767px){
    .cargo_consejo_conocenos{
        text-align: left;
        font-size: 12px;
    }
    
    .empresa_consejo_conocenos{
        text-align: left;
        font-size: 12px;
    }
}