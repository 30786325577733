.banner_principal_productos{
    
    background-size: 110% 110%;
    background-position: center center;
    animation: shrink 5s infinite alternate;
}

.logo_marca{
    width: 60%;
}

.nombre_marca{
    font-size: 25px;
    font-weight: bold;
}

.contenedor_producto{
    border-top: 1px solid black;
    padding: 5px 0px;
}

.img_marca_productos{
    width: 100%;
}

.texto_header_tabla{
    font-size: 12px;
    text-align: left;
    margin-top: 30px;
    padding:10px 12px;
    font-weight: bold;
}

.marca_productos, .usos_productos, .tipo_harina_productos, .usos_productos, 
.presentacion_productos, .comentarios_productos{
    font-size: 11px;
    text-align: justify;
    padding:0px 12px;
}

.url_productos{
    font-size: 12px;
    word-wrap: break-word;
}
