.titulo_revista_reportes{
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.img_principal_publicacion{
    width: 100%;
    height: 600px;
}

.img_carousel_r{
    width: 100%;
    padding: 0px 5px 0px 0px;
    cursor: pointer;
}