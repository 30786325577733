.banner_principal_cereales{
    height: cover;
    background: url('../images/sitio/3_cereales/banner_seccioncereales.jpg');
    background-size: 110% 110%;
    background-position: center center;
    animation: shrink 5s infinite alternate;
    padding: 5%;
  }
  @keyframes shrink {
    0% {
      background-size: 110% 110%;
    }
    100% {
      background-size: 100% 100%;
    }
  }

  .contenedor_icono_principal_cereales{
      text-align: center;
  }

  .img_icono_principal_cereales{
    width: 350px;
    margin-top: 0%;
  }

  .palabras_principal_cereales{
      color:white;
      font-size: 5em;
      font-weight: bold;
  }

  .texto_titulo_cereales{
      font-size: 25px;
      font-weight: bold;
      padding-bottom: 0px;
      margin-bottom: 0px;
      text-align: left;
      color:black;
  }

  .texto_info_cereales{
      font-size: 1.5em;
      text-align: justify;
      margin-top:20px;
      margin-bottom: 20px;
  }

  .img_tazon_cereales{
      width:500px;
      float:right;
  }
.ver_tazon{
  display: none;
}
  @media screen and (max-width:767px){
    .img_tazon_cereales{
        width:500px;
    }
    .no_ver_tazon{
      display: none;
    }
    .ver_tazon{
      display: block;
    }
    .img_icono_principal_cereales{
      width: 250px;
      margin-top: 0%;
    }
    .palabras_principal_cereales{
      color:white;
      font-size: 3em;
      font-weight: bold;
  }

  }

  .contenedor_header_tabla_propiedades{
      background-color:black;
  }

  .datos_menu_superior{
      color:white;
      font-weight: bold;
      text-align: center;
      padding-top:10px;
      padding-bottom: 10px;
  }

  .datos_menu_izquierdo{
    color:white;
    font-weight: bold;
    text-align: center;
    padding-top:5px;
    padding-bottom: 5px;
    border-bottom: 1px #fccd35 solid;
  }

  .datos{
      text-align: center;
      border-bottom: 1px lightgray solid;
      font-weight: bold;
      padding-top:5px;
    padding-bottom: 5px;
  }

  .img_texto_alimenticio{
      width: 100%;
  }

  .img_plato_alimenticio{
      width: 100%;
  }

  .texto_alimenticio{
      text-align: justify;
      font-size: 1.5em;
  }