.link_red_social{
  color:#ffffff;
  font-size: 12px;
  text-decoration: none;
}
.link_red_social:hover{
  color:#fccd35;
}
.img_red_social_text{
  width: 20px;
}
.img_red_social{
  width: 25px;
}
.logo{
  width: 160px;
}

.content_wsp_fixed{
  position: fixed;
  z-index: 98;
  left: 90%;
  top: 90%;
}
.sticky_wsp{
  width: 50px;
}
.content_wsp_fixed:hover .sticky_wsp{
  content: url('../images/sitio/1_HOME/btn_whatsam.png');
}

.content_logo_fixed{
  position:absolute;
  z-index: 98;
  left: 63%;

}
.sticky_logo{
  width: 230px;
}


.contenedor_seccion_menu{
  padding:0px 10px;
  position:relative;
}

.contenedor_menu{
  margin-top: 5px;
  padding: 10px 0px 5px 0px;
  font-family: 'Myriad Pro Semibold';
}
.contenedor_menu_select{
  margin-top: 5px;
  padding: 10px 0px 5px 0px;
  border-top: 2px solid #fccd35;
  font-family: 'Myriad Pro Semibold';
}
.link_menu{
  color:#000000;
  font-size: 1.1em;
}
.link_menu:hover{
  color: #fccd35;
}
.link_menu_selec{
  color: #fccd35;
  font-size: 1.1em;
  text-transform: uppercase;
}
.link_menu_selec:hover{
  color: #fccd35;
}
.contenedor_submenu{
  z-index: 99;
}
.contenedor_submenu_row{
  position:absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 200px;
  text-align: left;
  padding: 7px;
  text-transform: uppercase;
}
.contenedor_submenu_row_publicaciones{
  position:absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 250px;
  text-align: left;
  padding: 7px;
  text-transform: uppercase;
}
.contenedor_submenu_row_prodcutos{
  position:absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 350px;
  text-align: left;
  padding: 7px;
  text-transform: uppercase;
}
.link_submenu{
  color:#000000;
  font-size: 0.9em;
}
.link_submenu:hover{
  color:#fccd35;
  font-size: 0.9em;
}


/* HEADER MOVIL */
.contenedor_header_movil{
  background-color: #000000;
  padding: 5px 0px;
}
.contenedor_icono_hamburguesa{
  color:white;
  text-align: right;
}
.logo_movil{
  width: 25px;
}
.icono_movil{
  color:white;
  font-size:2em;
}
.icono_cerrar_drawer{
  color:white;
  font-size:20px; 
  font-weight:bold;
}
.contendor_link_movil{
  text-align:left;
}
.link_item_menu_movil{
  text-decoration:none !important;
  color: white;
  text-transform:none;
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: 'MyriadPro Regular';
}
.col_menu_movil{
  position: absolute;
  z-index: 99;
  background-color: #fccd35;
  width: 100%;
  padding: 3px 15px;
}
@media screen and (max-width: 1200px) {
  .link_menu{
    font-size: 0.9em;
  } 
  .link_menu_selec{
    font-size: 0.9em;
  } 
}
@media screen and (max-width: 790px) {
  .content_logo_fixed{
    position: fixed;
    z-index: 98;
    left: 60%;
  }
  .sticky_logo{
    width: 150px;
  }
  
}
@media screen and (max-width: 790px) {
  .content_logo_fixed{
    position: fixed;
    z-index: 98;
    left: 40%;
  }
  .sticky_logo{
    width: 100px;
  }
  
}