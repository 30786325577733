.banner_harina{
  height: cover;
  background: url('../images/sitio/5_HARINA/banner_seccionharina.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s infinite alternate;
  padding: 5%;
}
@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.animacion_img {
  width: 60%;
  position: relative;
  animation: mymove 3s 1;
}
.animacion_img_gift {
  width: 100%;
  position: relative;
  animation: mymove 3s 1;
}
@keyframes mymove {
  from {left: 200px;}
  to {left: 0px;}
}
@keyframes mymove3 {
  from {top: 200px;}
  to {top: 0px;}
}
.animacion_img2 {
  width: 100%;
  position: relative;
  animation: mymove2 3s 1;
}
@keyframes mymove2 {
  from {right: 200px;}
  to {right: 0px;}
}
.icono_harina{
  width: 350px;
  margin-top: 5%;
}
.text_banner_harina{
  font-size: 5em;
  color: #ffffff;
  font-family: 'MyriadPro Regular';
  font-weight: bold;
  margin-bottom: 0px;
}
.contenedor_cintillo{
  margin: 10px 0px;
  background-color: #fccd35;
}
.cintillo_harina{
  color: #000000;
  font-size: 2em;
  font-weight: bold;
  margin: 0px;
  text-align: left;
}

.text_definicion_harina{
  text-align: justify;
  color: #686f7b;
  font-size: 1.5em;
  font-family: 'MyriadPro Regular';
}

/* HISTORIA */
.banner_historia_harina{
  height: 50vh;
  background-image: url('../images/sitio/5_HARINA/banner_historiamolienda.jpg');
  background-size: 100% 100%;
  padding: 6%;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  animation: shrink 5s 2 alternate;
  vertical-align: middle;
}
.texto_banner_historia{
  color:#ffffff;
  font-size: 5em;
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
}
.especio_contenedor{
  padding: 3% 0% 0% 0%;
}

/* CLASIFICACIÓN */
.table_animacion{
  position: relative;
  animation: mymove3 4s 1;
}
.titu_tablas_harina{
  color:#000000;
  font-family: 'MyriadPro Bold';
  font-size: 2em;
  margin-bottom: 0px;
  margin-top: 10px;
}
.cabecera_tabla_harina{
  color:#ffffff;
  background-color: #000000;
  font-family: 'MyriadPro Bold';
  font-size: 1.3em;
  text-align: left;
}
.cabecera_pading{
  padding-left: 2%;
}
.tab_harina_caracteristicas{
  color:#686f7b;
  font-size: 1.2em;
  text-align: left;
  background-color: #ffffff;
  vertical-align: top;
  padding: 2%;
}
.tab_harina_uso{
  color: #000000;
  background-color: #fccd35;
  font-size: 1.2em;
  text-align: left;
  vertical-align: top;
  padding: 2%;
}

@media screen and (max-width: 991px) {
  .cintillo_harina{
    font-size: 1.6em;
  }
  .texto_banner_historia{
    font-size: 3em;
  } 
  .icono_harina{
    width: 250px;
    margin-top: 15%;
  }
}