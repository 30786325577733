.banner_principal{
  height: cover;
  background: url('../images/sitio/1_HOME/banner_header_trigo.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 8%;
}
@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.slick-dots{
  bottom: 10px!important;
}
.slick-dots li.slick-active button:before{
  color: #fff!important;
}
.banner_primera_linea{
  font-size: 3em;
  margin: 0px;
}
.banner_segunda_linea{
  font-size: 3.5em;
  font-weight: bold;
  margin: 0px;
  line-height: 1.5;
}
.banner_text{
  font-size: 1.1em;
}
.linea_blc_home{
  border: 2px solid #ffffff;
  background-color: #ffffff;
}
/** BOTON CON ANIMACION **/
.contenedor_boton{
  text-align: -webkit-center;
}
.contenedor_boton a{
  width: fit-content;
}
.contenedor_boton span{
  width: fit-content;
}
.contenedor_boton_home_blog{
  text-align: -webkit-left;
}
.contenedor_boton_home_blog a{
  width: fit-content;
}
.btn-blanco {

  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ffffff;
  border: 2px solid #e74c3c;
  border-radius: 0.1em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  line-height: 1;
  padding: 0.6em 0.4em;
  text-decoration: none;
  text-align: center;
}
.btn-blanco:hover, .btn-blanco:focus {
  color: #fff;
  outline: 0;
  border-color: black;
}
.boton-blanco {
  border-color: white;
  color:#000000;
  background-image: -webkit-linear-gradient(90deg, #000000 50%, transparent 50%);
  background-image: linear-gradient(90deg, #000000 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.boton-blanco:hover {
  background-position: 0;
}


.btn-negro {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #e74c3c;
  border-radius: 0.1em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  line-height: 1;
  padding: 0.6em 1.6em;
  text-decoration: none;
  text-align: center;
}
.btn-negro:hover, .btn-negro:focus {
  color: #fff;
  outline: 0;
}

.boton-negro {
  border-color: black;
  color:#000000;
  background-image: -webkit-linear-gradient(90deg, #000000 50%, transparent 50%);
  background-image: linear-gradient(90deg, #000000 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}

.boton-negro:hover {
  background-position: 0;
}

.btn-amarillo {
  text-align: center;
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #000000;
  border: 2px solid #e74c3c;
  border-radius: 0.1em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  line-height: 1;
  padding: 0.6em 1.6em;
  text-decoration: none;
  text-align: center;
}
.btn-amarillo:hover, .btn-amarillo:focus {
  color: #fff;
  outline: 0;
}
.boton-amarillo {
  border-color: white;
  color:#ffffff;
  background-image: -webkit-linear-gradient(90deg, #fccd35 50%, transparent 50%);
  background-image: linear-gradient(90deg, #fccd35 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}

.boton-amarillo:hover {
  background-position: 0;
}

.btn-banner {

  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ffffff;
  border: 2px solid #e74c3c;
  border-radius: 0.1em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  line-height: 1;
  padding: 0.6em 0.4em;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
}
.btn-banner:hover, .btn-banner:focus {
  color: #000;
  outline: 0;
  border-color: #fccd35;
}
.boton-banner {
  border-color: white;
  color:#000000;
  background-image: -webkit-linear-gradient(90deg, #fccd35 50%, transparent 50%);
  background-image: linear-gradient(90deg, #fccd35 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.boton-banner:hover {
  background-position: 0;
}

.img_producto{
  width: 80%;
}
.desc_texto_productos{
  text-align: center;
  font-size: 1em;
  color:#5e5858;
}
.titulo_desc_producto{
  font-family: 'Myriad Pro Semibold';
  font-size: 2em;
  color:#000000;
  margin-bottom: 0px;
}
.titulo_desc_producto_amarillo{
  font-family: 'Myriad Pro Semibold';
  font-size: 2em;
  color:#fccd35;
  margin-bottom: 0px;
}
.contenedor_productos_desc{
  padding: 30px 0px 30px 0px;
}
.texto_pub_banner{
  font-family: 'CenturyGothic';
  font-size: 38px;
  color:#ffffff;
  text-align: center;
}
.texto_banner_descarga{
  font-size: 1.9em;
}
/* SECCION HISTORIA */
.contenedor_banner_historia{
  padding: 20px 0px 20px 0px;
  height:100%;
    background: -moz-linear-gradient(top, #000000 50%, #fccd35 50%);
    background: -webkit-linear-gradient(top, #000000 50%,#fccd35 50%);
    background: linear-gradient(to bottom, #000000 45%,#fccd35 45%);
}
.titulo_historia{
  font-weight: bold;
  font-size: 2.5em;
  border-bottom: 2px solid #fccd35;
}
.img_carousel_flecha{
  width:40px;
  cursor:pointer;
}
.img_carousel{
  width:100%;
  cursor:pointer;
}
.desc_historia{
  font-family: 'Myriad Pro Semibold';
  font-size: 1.1em;
}
.carousel_titu{
  font-family: 'Myriad Pro Semibold';
  font-weight: bold;
  font-size: 2em;
  text-align: left;
  margin-bottom: 0px;
}
.carousel_text{
  font-family: 'Myriad Pro Semibold';
  font-size: 1em;
  text-align: justify;

}
.contenedor_texto_carousel{
  padding: 20px 0px 0px 10px;
}
.contenedor_boton_banner{
  text-align: -webkit-left;
}
.contenedor_boton_banner a{
  width: fit-content;
}

/* seccion comercio */
.contenedor_comer_marco{
  height: 70vh;
  background: url('../images/sitio/1_HOME/banner_comeercio_y_normativas.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 3%;
}
.comercio_home_text{
  color: #ffffff;
  font-size: 2.5em;
  font-family: 'MyriadPro Bold'; 
  border-bottom: 2px solid #ffffff; 
}
.home_img_comercio{
  width: 300px;
}
.vertical_line{
  border-left: 3px solid white;
  height: 200px;
  margin:100%;
}

/* CADENAS PRODUCTIVAS */
.contenedor_cadenas{
  padding: 40px 0px 20px 0px;
}
.contenedor_info_cadenas{
  padding: 0px 10px;
}
.titulo_desc_cadenas{
  font-family: 'Myriad Pro Semibold';
  font-size: 1.5em;
  color:#000000;
  margin-bottom: 0px;
}
.titulo_desc_cadenas_amarillo{
  font-family: 'Myriad Pro Semibold';
  font-size: 1.5em;
  color:#fccd35;
  margin-bottom: 0px;
}
.text_decs_cadenas{
  font-family: 'MyriadPro Regular';
  color:#686f7b;
  font-size: 0.9em;
}
.img_desc_cadenas{
  width: 100%;
}
.titulo_cadenas_home{
  font-weight: bold;
  font-size: 2.5em;
  border-bottom: 2px solid #000000;

}

/* YO ME QUEDO EN CASA */
.contenedor_encasa{
  height: 100%;
  background: url('../images/sitio/1_HOME/banner_molinos.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 6% 0%;
}
.barra_encasa{
  background-color: #fccd35;
  color:#000000;
  text-align: left;
  font-size: 3em;
  padding-left: 5px;
}
.text_encasa{
  color:#ffffff;
  font-family: 'CenturyGothic';
  font-size: 2.6em;
}

/* PUBLICACIONES */
.contenedor_banner_publicaciones{
  padding: 6% 6%;
}
.titulo_publcaciones_home{
  font-weight: bold;
  font-size: 2.5em;
  border-bottom: 2px solid #000000;

}
.container_publicaciones {
  position: relative;
  width: 50%;
}
.img_carousel_publicaciones {
  display: block;
  width: 100%;
  height: auto;
  cursor:pointer;
}
.overlay {
  position: absolute;
  top: 3%;
  bottom: 0;
  left: 3%;
  right: 0;
  height: 85%;
  width: 94%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fccd35;
  cursor:pointer;
}
.container_publicaciones:hover .overlay {
  opacity: 0.5;
  cursor:pointer;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* BLOG HOME */
.contenedor_blog_home{
  height: cover;
  background: url('../images/sitio/1_HOME/banner_blog.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 6% 0%;
}
.titulo_blog_home{
  text-align: left;
  font-weight: bold;
  font-size: 3em;
  border-bottom: 2px solid #000000;
  font-family: 'MyriadPro Bold';
}
.sub_blog_home{
  text-align: left;
  font-weight: bold;
  font-size: 1.6em;
  font-family: 'MyriadPro Regular';
}
.ocultar_blog{
  display: none;
}
.ver_blog{
  display: block;
}
.text_blog_home{
  text-align: justify;
  font-weight: bold;
  font-size: 1em;
  font-family: 'MyriadPro Regular';
}
.blog_mas_blanco{
  background-color: #ffffff;
  color: #000000;
  border: 2 solid #000000;
  line-height: 1;
  padding: 0.6em 0.6em;
  text-decoration: none;
  margin: 0.6em;
  text-decoration: none;
}
.blog_mas_negro{
  background-color: #000000;
  color: #ffffff;
  border: #ffffff;
  line-height: 1;
  padding: 0.6em 0.6em;
  text-decoration: none;
  margin: 0.6em;
  text-decoration: none;
}
.contenedor_div_publi{
  padding:10px;
  background-color: #ffffff;
  position: relative;
}
.div_publicacion{
  background-color: #ffffff;
  border: 1px solid #000000;

}
.img_div_publi{
  width: 100%;
}
.titu_div_publi{
  font-family: 'MyriadPro Regular';
  font-weight: bold;
  font-size: 1.4em;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.desc_div_publi{
  font-family: 'MyriadPro Regular';
  font-size: 0.8em;
  text-align: justify;
  padding-left: 5px;
  padding-right: 5px;
}
.fecha_div{
  position: absolute; 
  left: 80%; 
  top: 10%; 
  z-index: 1;
  background-color: #ffffff;
  line-height: 1;
  padding: 2px;
}
.dia_div{
  font-family: 'MyriadPro Regular';
  font-weight: bold;
  font-size: 2em;
}
.mes_div{
  font-family: 'MyriadPro Regular';
  font-size: 0.6em;
}

/* Logos de productos */
.carousel_productos{
  width: 100%;
}
.contenedor_logo_producto{
  padding: 5px;
}

@media screen and (max-width: 2640) {
  
}
@media screen and (max-width: 1200) {
  .banner_primera_linea{
    font-size: 2.3em;
    line-height: 1;
  }
  .banner_segunda_linea{
    font-size: 2.5em;
    line-height: 1;
  }
  .banner_text{
    font-size: 1em;
    line-height: 1;
  }
}
@media screen and (max-width: 991px) {
  .banner_principal{
    height: cover;
    padding: 6%;
  }
  .banner_primera_linea{
    font-size: 2em;
  }
  .banner_segunda_linea{
    font-size: 2.1em;
  }
  .img_producto{
    width: 60%;
  }
  .desc_historia{
    font-size: 1em;
  }
  .btn-negro, .boton-negro, .historia_slider{
    display: none;
  }
  .barra_encasa{
    font-size: 2em;
  }
  .text_encasa{
    font-size: 1.4em;
  }
  .carousel_productos{
    width: 100%;
  }
  .banner_text{
    font-size: 1em;
    line-height: 1;
  }
  .texto_pub_banner{
    font-size: 28px;
  }
}
@media screen and (max-width: 991px) {
  .banner_primera_linea{
    font-size: 1.8em;
  }  
  .banner_segunda_linea{
    font-size: 1.9em;
  }
  .barra_encasa{
    font-size: 1.8em;
  }
}
@media screen and (max-width: 551px) {
  .banner_primera_linea{
    font-size: 1.8em;
  }  
  .banner_segunda_linea{
    font-size: 1.9em;
  }
  .barra_encasa{
    font-size: 1.6em;
  }
  .contenedor_encasa{
    height: 100%;
    background: url('../images/sitio/1_HOME/banner_molinos.jpg');
    background-size: cover;
    background-position: center center;
    animation: none;
  }
  .contenedor_blog_home{
    height: cover;
    background: url('../images/sitio/1_HOME/banner_blog.jpg');
    background-size: cover;
    background-position: center center;
    animation: none;
  }
}




/*botones*/
