.banner_panificaion{
  height: cover;
  background: url('../images/sitio/7_CADENAS_PRODUCTIVAS/banner_panificacion.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 6%;
  text-align: center;
}
@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.icono_banner{
  width: 250px;
}
.text_banner{
  font-size: 5em;
  color: #fccd35;
  font-family: 'MyriadPro Regular';
  font-weight: bold;
  margin-bottom: 0px;
}
.titu_panificacion{
  font-family: 'Cream Peach';
  color: #000000;
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 0px;
  text-align: left;
}
.text_definicion_cadenas{
  text-align: justify;
  color: #686f7b;
  font-size: 1.5em;
  font-family: 'MyriadPro Regular';
}
.contenedor_cadenas{
  padding: 3% 0% 3% 0%;
}
.img_cadenas_titulo{
  width: 80%;
}
.img_cadenas{
  width: 100%;
}
.img_cadenas_trigo{
  width: 60%;
}
/* SEMOLA Y PASTA */
.banner_pasta{
  height: cover;
  background: url('../images/sitio/7_CADENAS_PRODUCTIVAS/banner_pasta.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 6%;
  text-align: center;
}
/* SALVADO Y GÉRMEN */
.banner_germen{
  height: cover;
  background: url('../images/sitio/7_CADENAS_PRODUCTIVAS/banner_salvado.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 6%;
  text-align: center;
}
/* GLUTEN */
.banner_gluten{
  height: cover;
  background: url('../images/sitio/7_CADENAS_PRODUCTIVAS/banner_gluten.jpg');
  background-size: 110% 110%;
  background-position: center center;
  animation: shrink 5s 2 alternate;
  padding: 6%;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .icono_banner{
    width: 150px;
  }
  .text_banner{
    font-size: 3em;
  }
  .titu_panificacion{
    font-size: 3em;
  }
  .text_definicion_cadenas{
    text-align: justify;
    color: #686f7b;
    font-size: 1.3em;
    font-family: 'MyriadPro Regular';
  }
  .img_cadenas{
    width: 70%;
  }
}