.banner_seccion_comercio{
  height: 100%;
  width: 100%;
  background: url('../images/sitio/9_COMERCIO_EXT_MARCO_NORM/banner_comercioexterior.jpg');
  background-size: 100% 100%;
  background-position: center center;
  animation: shrink 5s 3 alternate;
  padding: 3%;
}
@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.contenedor_seccion_comercio{
  padding: 3% 0%;
}
.titulo_banner_seccion_comercio{
  color: #ffffff;
  font-size: 3em;
  font-family: 'MyriadPro Regular';
  font-weight: bold;
  margin: 0px;
  
}
.ico_trigo, .ico_link{
  width: 60px;
  padding: 10px;
}
.titu_comercio{
  font-family: 'MyriadPro Regular';
  font-size: 1.5em;
  color:#000000;
  text-align: left;
  margin: 0px;
  padding: 0px 3px;
}
.texto_comercio{
  font-family: 'MyriadPro Regular';
  font-size: 1.5em;
  color:#000000;
  text-align: left;
  margin: 0px;
  padding: 0px 3px;
}
.btn_comercio{
  border: 2px solid #000000;
  color: #ffffff;
  background-color: #000000;
  padding: 5px 10px;
  font-size: 1.5em;
}
.btn_comercio:hover{
  color: #000000;
  background-color: #fccd35;
  border: 2px solid #fccd35;
}
.contenedor_todo:hover p.titu_comercio{
  color: #fccd35;
  font-weight: bold;
}
.contenedor_todo:hover img.ico_trigo{
  content: url('../images/sitio/9_COMERCIO_EXT_MARCO_NORM/icono_trigo_amarillo.png');
}

/*SECCION MARCO NORMATIVO*/
.banner_seccion_marco{
  height: 100%;
  width: 100%;
  background: url('../images/sitio/9_COMERCIO_EXT_MARCO_NORM/banner_marconormativo.jpg');
  background-size: 100% 100%;
  background-position: center center;
  animation: shrink 5s 3 alternate;
  padding: 3%;
}
.contenedor_todo:hover img.ico_link{
  content: url('../images/sitio/9_COMERCIO_EXT_MARCO_NORM/link_icono_amarillo.png');
}
.cabeza_tabla_marco{
  font-family: 'MyriadPro Regular';
  text-align: left;
  color:#000000;
  font-size: 1.6em;
  font-weight: bold;
  margin: 0px;
}
@media screen and (max-width: 991px) {
  .cabeza_tabla_marco{
    font-size: 1.6em;
  }
  .titu_comercio{
    font-size: 1.3em;
  }
  .btn_comercio{
    border: 2px solid #000000;
    color: #ffffff;
    background-color: #000000;
    padding: 3px 6px;
    font-size: 1.1em;
  }
}