.imge_encabezado_nota_blog{
  width: 100%;
}
.titulo_nota{
  font-family: 'MyriadPro Regular';
  font-size: 2em;
  color:#000000;
  text-align: left;
  text-transform: uppercase;
}
.contenedor_nota_texto{
  padding-left: 3%;
}
.nota_texto{
  font-size: 1.2em;
  color: #686f7b;
  text-align: left;
}